


































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Spinner from '~/components/PassporterUI/Spinner.vue'
import Dialog from '~/components/UI/Dialog.vue'
import PIconChevron from '~/components/PassporterUI/Icon/PIconChevron.vue'
import BasicScreen from '~/components/context/auth/BasicScreen.vue'
import AuthSections from '~/components/context/auth/AuthSections.vue'
import { AuthService } from '~/services/auth/service'
import TokenService from '~/passporter-services/shared/token'

export type LoginSection =
	| 'basic'
	| 'magicLink'
	| 'confirmMagicLink'
	| 'login'
	| 'signup'
	| 'forgotPassword'

export default Vue.extend({
	name: 'AuthView',
	components: {
		AuthSections,
		BasicScreen,
		PIconChevron,
		Dialog,
		Spinner,
	},
	props: {
		signup: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			basicSection: 'basic' as LoginSection,
			section: undefined as LoginSection | undefined,
			leadId: null as null | string,
			leadCreationInProgress: false as boolean,
			signupTime: {
				start: null as number | null,
				finish: null as number | null,
			},
			itineraryId: undefined as string | undefined,
			token: undefined as string | undefined,
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
			isAuthenticated: 'auth/isAuthenticated',
			user: 'auth/user',
			userId: 'auth/userId',
		}),
		previousRoute(): string {
			return this.$nuxt.context.from?.path
		},
		isBasicSection(): boolean {
			return this.section === this.basicSection
		},
		showBackButton(): boolean {
			return !this.screenSize.xs && !this.isBasicSection
		},
		destination(): string | undefined {
			return (this.$route.query.d as string) ?? undefined
		},
		authService(): AuthService {
			return new AuthService(this.$repositories.auth)
		},
	},
	watch: {
		isAuthenticated(isAuthenticated) {
			if (isAuthenticated && !this.needsThankYouPage()) {
				this.onUserAuthenticated()
			}
		},
	},
	created() {
		this.token = (this.$route.query.token as string) ?? null
		this.section = (this.$route.query.s as LoginSection) ?? this.basicSection
		this.leadId = (this.$route.query.lead_id as string) ?? null
		this.itineraryId = (this.$route.query.itinerary as string) ?? null
	},
	async mounted() {
		if (this.token && !this.userId) {
			await this.handleLogin()

			if (this.needsThankYouPage()) {
				this.goToThanksPage()
				return
			}
		}

		if (this.token && this.userId && this.needsThankYouPage()) {
			if (this.userEmailMatchingLoggedUser()) {
				this.goToThanksPage()
			} else {
				this.handleDifferentUsers()
			}
			return
		}

		if (this.userId) {
			await this.goHome()
		}

		if (this.signup) {
			this.addTrackers()
			this.signupTime.start = new Date().valueOf()
		}
	},
	methods: {
		onSectionClicked(section: LoginSection) {
			this.section = section
		},
		async handleLogin() {
			try {
				const response = await this.authService.login({ token: this.token })
				await this.$store.dispatch('auth/login2', response)
			} catch (e: any) {
				await this.handleLoginError(e)
				await this.$store.dispatch('auth/openLogin')
			}
		},

		async onUserAuthenticated() {
			const authRoutes = ['/home', '/profile', '/itineraries', '/destinations']

			if (
				this.previousRoute &&
				authRoutes.some((authRoute) => {
					const previousRoute = this.previousRoute.slice(3)
					return previousRoute.startsWith(authRoute)
				})
			) {
				await this.$router.replace(this.previousRoute)
			} else {
				await this.goHome()
			}
		},
		async goHome() {
			const homeRoute = this.$navigation.home.getHomeRoute()
			await this.$router.replace(homeRoute)
		},
		async handleDifferentUsers() {
			await this.$repositories.auth.logout()
			await this.$store.dispatch('auth/openLogin')
		},
		async handleLoginError(error: any) {
			const failed = error.response?.data?.code === 'authentication_failed'
			if (failed) {
				error.formErrors = { email: ' ', password: ' ' }
				throw error
			}
			await this.$store.dispatch('error', error, { root: true })
		},
		userEmailMatchingLoggedUser(): boolean {
			const newUserToken = TokenService.getTokenInfo(this.token)
			return this.userId === newUserToken.id
		},
		needsThankYouPage(): boolean {
			const thanksQuery = this.$route.query['show-thankyou'] ?? undefined
			const queryToShowThanksPage = thanksQuery === 'True'

			return Boolean(this.itineraryId) && queryToShowThanksPage
		},
		goToThanksPage() {
			const thanksUrl = this.$navigation.voucher.getThankYouRoute(this.itineraryId!)
			this.$router.push(thanksUrl)
		},

		onBack() {
			if (this.section === 'forgotPassword') {
				this.onSectionClicked('login')
			} else {
				this.onSectionClicked(this.basicSection)
			}
		},
		checkAuth() {
			if (this.isAuthenticated) {
				this.$nuxt.$emit('authenticated')
			}
			if (this.signup && !this.previousRoute?.includes('itineraries')) {
				setTimeout(() => {
					this.$nuxt.$emit('open-welcome-lightbox')
				}, 2000)
			}
		},
		onSignUpSuccess() {
			this.signupTime.finish = new Date().valueOf()
			let signupMillis
			if (this.signupTime.start) {
				signupMillis = this.signupTime.finish - this.signupTime.start
			}
			try {
				this.$mixpanel?.track('signup success', {
					signup_time: this.formatToString(signupMillis),
					destination_name: this.destination ?? undefined,
				})
			} catch (err: any) {}
		},
		formatToString(milliseconds?: number): string | undefined {
			if (!milliseconds) return undefined
			return new Date(milliseconds).toISOString().slice(11, 19)
		},
		addTrackers() {
			if (this.destination) {
				this.addDestinationImpressiionTracker()
			} else {
				this.$mixpanel?.track('signup impression')
			}
		},
		addDestinationImpressiionTracker() {
			this.$mixpanel?.track('signup destination impression', {
				destination_name: this.destination,
			})
		},
	},
})
